import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import qs from "../data/questions.json";
import multi from "../data/multi.json";
import Button from "../componets/UI/Button";
import { Form, Formik, Field } from "formik";
import FormikRadioGroup from "../componets/UI/FormikRadioGroup";
import FormikInput from "../componets/UI/FormikInput";

const MAX_SCORE = 38;

interface FormValues {
  patientName: string;
  answers: number[];
  multick: boolean[];
}

export const AnxietyTest = ({
  _patient,
  _cb,
}: {
  _patient?: string;
  _cb?: () => void;
}) => {
  const [questions, setQuestions] = useState<string[]>([]);
  const [multiQuestions, setMultiQuestions] = useState<string[]>([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [points, setPoints] = useState<number>(0);
  const [score, setScore] = useState<string>("");
  const [result, setResult] = useState<string>("");

  useEffect(() => {
    setQuestions(qs);
    setMultiQuestions(multi);
  }, []);

  const handleSubmit = ({ patientName, answers, multick }: FormValues) => {
    const patient = !_patient ? patientName.trim() : _patient;
    if (patient.length > 0 && answers.every((x) => x >= 0)) {
      const points =
        answers.reduce((prev, cur) => prev + cur, 0) +
        multick.reduce((prev, cur) => prev + (cur ? 1 : 0), 0);
      const score = ((points / MAX_SCORE) * 100).toFixed(2);
      const result =
        +score >= 0 && +score <= 30
          ? "Low Anxiety"
          : +score >= 31 && +score <= 45
          ? "Moderate Anxiety"
          : "Severe Anxiety";
      setPoints(points);
      setScore(score);
      setResult(result);
      setIsSubmitted(true);
      emailjs.send(
        "service_4ni9h3m",
        "template_ob72mth",
        {
          test: "Anxiety Symptoms Questionnaire",
          patient,
          points,
          max: MAX_SCORE,
          score,
          result,
        },
        "QJlt6nX2Yp70JoG43"
      );
      if (patient) _cb!();
    }
  };

  return (
    <>
      <div className="flex w-full md:w-2/3 flex-col items-start justify-between p-2 mt-5 md:self-center">
        <h1 className="text-3xl text-blue-900 mb-5">
          Anxiety Symptoms Questionnaire
        </h1>

        <Formik
          onSubmit={async (values) => handleSubmit(values)}
          initialValues={{
            patientName: "",
            answers: qs.map((_) => -1),
            multick: multi.map((_) => false),
          }}
        >
          <Form className="w-full flex flex-col items-start justify-center">
            {!_patient && (
              <FormikInput
                id="patientName"
                label="Patient Name"
                type="text"
                classnm="mb-3 border-green-500 text-green-500"
                classlabel="text-green-900"
              />
            )}

            {questions.map((item, index) => (
              <FormikRadioGroup
                key={index}
                name={`answers[${index}]`}
                label={item}
                options={[
                  { label: "Yes", value: 1 },
                  { label: "No", value: 0 },
                ]}
              />
            ))}

            <h2 className="text-xl text-blue-900 mt-3">
              Do you experience or fear any of the following?
            </h2>

            <div
              className="flex w-full flex-col p-2 text-lg"
              role="group"
              aria-labelledby="checkbox-group"
            >
              {multiQuestions.map((mq, index) => (
                <label key={index} className="flex">
                  <Field type="checkbox" name={`multick[${index}]`} />
                  <span className="ml-3">{mq}</span>
                </label>
              ))}
            </div>

            <Button
              type="submit"
              className="w-full mt-5 bg-green-900 text-white text-lg text-center font-bold"
            >
              Submit
            </Button>
          </Form>
        </Formik>
      </div>

      {isSubmitted && (
        <div className="text-center w-full flex flex-col justify-center items-center my-6">
          <span className="text-2xl m-2">
            Points: {points} / {MAX_SCORE} ({score}%)
          </span>
          <span className="text-2xl m-2">Result: {result}</span>
        </div>
      )}
    </>
  );
};
