import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";

export type XRadioGroupOption<TValue> = {
  label: string;
  value: TValue;
};
export type XRadioGroupProps<TValue> = {
  label: string;
  value: TValue;
  onChange(newVal: TValue): void;
  options: XRadioGroupOption<TValue>[];
};

const XRadioGroup = <TValue,>(props: XRadioGroupProps<TValue>) => {
  return (
    // use the value and onChange from props.
    <RadioGroup value={props.value} onChange={props.onChange}>
      <RadioGroup.Label className="text-lg font-bold my-2">
        {props.label}
      </RadioGroup.Label>
      {/* render each option. */}
      <div className="flex flex-col gap-2 w-72">
        {props.options.map((option) => {
          return (
            <RadioGroup.Option value={option.value} key={option.label}>
              {/* Use renderProps to get the checked state for each option. */}
              {/* Render the state appropriately using tailwind classes */}
              {({ checked }) => (
                <div
                  className={classNames({
                    "flex gap-2 rounded-md px-2 py-1 border-2 cursor-pointer w-3/4":
                      true,
                    "outline outline-1": checked,
                  })}
                >
                  <span className="w-5 h-5 flex justify-center items-center">
                    {checked ? (
                      <div className="w-5 h-5 rounded-full border-2 border-blue-700 flex justify-center items-center">
                        <div className="w-2 h-2 m-1 rounded-full bg-blue-700"></div>
                      </div>
                    ) : (
                      <div className="w-5 h-5 rounded-full border-2 border-blue-700"></div>
                    )}
                  </span>
                  <RadioGroup.Label>{option.label}</RadioGroup.Label>
                </div>
              )}
            </RadioGroup.Option>
          );
        })}
      </div>
    </RadioGroup>
  );
};
export default XRadioGroup;
