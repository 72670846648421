import { useNavigate } from "react-router-dom";
import Button from "../componets/UI/Button";
import Input from "../componets/UI/Input";
import { FormEvent, useRef } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import { compare } from "bcryptjs";

export const PatientAuth = () => {
  const navigate = useNavigate();
  const idRef = useRef<HTMLInputElement>(null);
  const codeRef = useRef<HTMLInputElement>(null);

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();

    if (!idRef.current || !codeRef.current) return;

    const docID = idRef.current.value.trim();
    const accessCode = codeRef.current.value.trim();

    try {
      const docSnap = await getDoc(doc(db, "patients", docID));
      if (!docSnap.exists()) throw new Error("Patient could not be found");

      const patient = docSnap.data();
      if (!(await compare(accessCode, patient.accessCodeHash)))
        throw new Error("Incorrent Access Code");

      navigate(`/patient/${docID}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <main className="flex flex-col items-center w-full">
      <h1 className="text-center text-green-500 text-3xl font-bold my-5">
        Login
      </h1>
      <form
        className="flex flex-col justify-center items-center w-full md:w-1/2 m-3 p-2 bg-gray-100 text-green-500 rounded-md shadow-xl"
        onSubmit={handleLogin}
      >
        <Input id="doc" label="Patient ID" type="text" ref={idRef} />
        <Input id="code" label="Auth Code" type="password" ref={codeRef} />

        <Button
          type="submit"
          className="font-bold border-green-500 border-2 rounded w-1/2 md:w-1/3"
        >
          Begin Examination
        </Button>
      </form>
    </main>
  );
};
