import { useEffect, useState } from "react";
import { auth } from "../../firebase";
import DropMenu from "../UI/DropMenu";
import { onAuthStateChanged } from "@firebase/auth";

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...
        console.log("uid", uid);
        setIsLoggedIn(true);
      } else {
        // User is signed out
        // ...
        console.log("user is logged out");
        setIsLoggedIn(false);
      }
    });
  }, []);
  return (
    <header className="flex justify-between items-center text-center bg-green-500 text-white border-x-8 md:border-x-[2rem] border-green-900 h-24">
      <img src="logo.png" alt="LOGO" className="h-10 w-40" />

      <h1 className="text-xl text-center md:text-2xl m-0 title-font hidden md:inline">
        Shoreham Examination
      </h1>

      {isLoggedIn && <DropMenu />}
    </header>
  );
};

export default Header;
