import { PropsWithChildren } from "react";

const ArrowLeft = (props: PropsWithChildren<{ className?: string }>) => {
  return (
    <svg
      className={`h-8 w-8 text-white ${props.className}`}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      {" "}
      <circle
        cx="12"
        cy="12"
        r="10"
      /> <polyline points="12 8 8 12 12 16" />{" "}
      <line x1="16" y1="12" x2="8" y2="12" />
    </svg>
  );
};

export default ArrowLeft;
