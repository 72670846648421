import { Outlet } from "react-router-dom";
import Header from "./componets/Header/Header";

function App() {
  return (
    <>
      <Header></Header>
      <div className="w-full flex flex-col justify-center">
        <Outlet></Outlet>
      </div>
    </>
  );
}

export default App;
