import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <main className="flex flex-col w-full justify-center items-center h-[60vh]">
      <h1 className="text-2xl text-green-700 font-bold my-2">Select</h1>
      <section className="flex flex-col w-2/3 md:w-1/4 p-2 justify-center items-center bg-gray-300 shadow-lg rounded-sm">
        <Link
          to="/staff"
          className="rounded w-full m-3 p-2 text-white bg-green-700 text-center md:text-lg"
        >
          I am a Staff Member
        </Link>
        <Link
          to="/patient"
          className="rounded w-full m-3 p-2 text-white bg-green-700 text-center md:text-lg"
        >
          I am a Patient
        </Link>
      </section>
    </main>
  );
};
