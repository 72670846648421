import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import qs from "../data/p3.json";
import Button from "../componets/UI/Button";
import { Form, Formik } from "formik";
import FormikRadioGroup from "../componets/UI/FormikRadioGroup";
import FormikInput from "../componets/UI/FormikInput";

const MAX_SCORE = 85;

interface FormValues {
  patientName: string;
  answers: number[];
}

export const P3Test = ({
  _patient,
  _cb,
}: {
  _patient?: string;
  _cb?: () => void;
}) => {
  const [questions, setQuestions] = useState<string[][]>([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [points, setPoints] = useState<number>(0);
  const [score, setScore] = useState<string>("");
  const [result, setResult] = useState<string>("");

  useEffect(() => {
    setQuestions(qs);
  }, []);

  const handleSubmit = ({ patientName, answers }: FormValues) => {
    const patient = !_patient ? patientName.trim() : _patient;
    if (patient.length > 0) {
      const points = answers.reduce((prev, cur) => prev + cur, 0) - 44;
      const score = ((points / MAX_SCORE) * 100).toFixed(2);
      const result =
        points >= 0 && points <= 30
          ? "Low Emotion"
          : points >= 31 && points <= 50
          ? "Moderate Emotion"
          : "Severe Emotion";
      setPoints(points);
      setScore(score);
      setResult(result);
      setIsSubmitted(true);
      emailjs.send(
        "service_4ni9h3m",
        "template_ob72mth",
        {
          test: "P3 Test",
          patient,
          points,
          max: MAX_SCORE,
          score,
          result,
        },
        "QJlt6nX2Yp70JoG43"
      );
      if (patient) _cb!();
    }
  };

  return (
    <>
      <div className="flex w-full md:w-2/3 flex-col items-start justify-between p-2 mt-5 md:self-center">
        <h1 className="text-3xl text-blue-900 mb-5">P3 Test</h1>

        <Formik
          onSubmit={(values) => handleSubmit(values)}
          initialValues={{
            patientName: "",
            answers: qs.map((_) => 0),
          }}
        >
          <Form className="w-full flex flex-col items-start justify-center">
            {!_patient && (
              <FormikInput
                id="patientName"
                label="Patient Name"
                type="text"
                classnm="mb-3 border-green-500 text-green-500"
                classlabel="text-green-900"
              />
            )}

            <h2 className="text-xl text-blue-900 my-3">
              Please indicate how much have you been bothered by a symptom
              during the past month
            </h2>

            {questions.map((item, index) => (
              <FormikRadioGroup
                key={index}
                name={`answers[${index}]`}
                label={(index + 1).toString()}
                options={[
                  { label: item[0], value: 1 },
                  { label: item[1], value: 2 },
                  {
                    label: item[2],
                    value: 3,
                  },
                ]}
              />
            ))}

            <Button
              type="submit"
              className="w-full mt-5 bg-green-900 text-white text-lg text-center font-bold"
            >
              Submit
            </Button>
          </Form>
        </Formik>
      </div>

      {isSubmitted && (
        <div className="text-center w-full flex flex-col justify-center items-center my-6">
          <span className="text-2xl m-2">
            Points: {points} / {MAX_SCORE} ({score}%)
          </span>
          <span className="text-2xl m-2">Result: {result}</span>
        </div>
      )}
    </>
  );
};
