import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../componets/UI/Button";
import { db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import { customAlphabet } from "nanoid";
import { hash } from "bcryptjs";
import { Field, Form, Formik } from "formik";
import FormikInput from "../componets/UI/FormikInput";

interface AssignedTests {
  patientName: string;
  asq: boolean;
  bai: boolean;
  bdi: boolean;
  p3: boolean;
  mmpi: boolean;
}

const nanoid = customAlphabet(
  "23456789abcdefghkmnpqrtuvwxyzABCDEFGHKMNPQRTUVWXYZ",
  8
);

export const Admin = () => {
  const navigate = useNavigate();

  const [accessCode, setAccessCode] = useState<string>("");
  const [docID, setDocID] = useState<string>("");
  const [ptName, setPtName] = useState<string>("");

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...
        console.log("uid", uid);
      } else {
        // User is signed out
        // ...
        console.log("user is logged out");
        navigate("/");
      }
    });
  }, [navigate]);

  const handleGeneration = async (values: AssignedTests) => {
    const patient = values.patientName.trim();
    if (
      Object.values(values)
        .filter((x) => typeof x === "boolean")
        .some((x: boolean) => x) &&
      patient.length > 0
    ) {
      try {
        setPtName(patient);
        const accessCode = nanoid();
        const docRef = await addDoc(collection(db, "patients"), {
          accessCodeHash: await hash(accessCode, 10),
          ...values,
          patientName: patient,
        });
        setDocID(docRef.id);
        console.log("code generated");
        setAccessCode(accessCode);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("No Test was choosen");
      console.log(values);
    }
  };

  const handleReset = () => {
    setPtName("");
    setAccessCode("");
    setDocID("");
  };

  const copyHandlerCode = () => {
    navigator.clipboard.writeText(accessCode);
  };

  const copyHandlerID = () => {
    navigator.clipboard.writeText(docID);
  };

  return (
    <main className="flex flex-col w-full h-[80vh] justify-center items-center text-center">
      {!accessCode && (
        <div className="flex flex-col justify-around items-center w-3/4 md:w-1/3 bg-gray-200 text-green-500 shadow-lg rounded p-2">
          <h1 className="text-lg md:text-xl text-green-500 font-bold">
            Generate Patient Access Code
          </h1>

          <Formik
            onSubmit={handleGeneration}
            initialValues={{
              patientName: "",
              asq: false,
              bai: false,
              bdi: false,
              p3: false,
              mmpi: false,
            }}
          >
            <Form className="w-full flex flex-col items-center justify-center">
              <FormikInput
                id="patientName"
                label="Patient Name"
                type="text"
                classnm="border-green-500"
              />
              <label>
                <Field type="checkbox" name="asq" />
                <span className="ml-2">Axienty Symtoms Questionnaire</span>
              </label>
              <label>
                <Field type="checkbox" name="bai" />
                <span className="ml-2">Beck Anxiety Inventory</span>
              </label>
              <label>
                <Field type="checkbox" name="bdi" />
                <span className="ml-2">Beck Depression Inventory</span>
              </label>
              <label>
                <Field type="checkbox" name="p3" />
                <span className="ml-2">P3</span>
              </label>
              <label>
                <Field type="checkbox" name="mmpi" />
                <span className="ml-2">MMPI-2</span>
              </label>

              <Button
                className="bg-green-500 text-white rounded-sm font-bold"
                type="submit"
              >
                Generate Patient Access Code
              </Button>
            </Form>
          </Formik>
        </div>
      )}

      {accessCode && accessCode !== "" && (
        <section className="flex flex-col justify-center items-center w-3/4 md:w-1/3 py-2 bg-gray-200 text-green-500 shadow-lg rounded p-2">
          {ptName.length > 0 && (
            <h1 className="text-xl md:text-2xl text-center font-bold">
              ID and Access Code for Patient: {ptName}
            </h1>
          )}
          <span className="text-lg md:text-xl text-center tracking-wide mb-3">
            Patient ID:{" "}
            <span className="italic underline text-green-700">{docID}</span>
          </span>
          <Button
            className="border-2 border-green-500 font-bold"
            type="button"
            onClick={copyHandlerID}
          >
            Copy Patient ID
          </Button>
          <span className="text-lg md:text-xl text-center tracking-wide mb-3">
            Patient Access Code:{" "}
            <span className="italic underline text-green-700">
              {accessCode}
            </span>
          </span>
          <Button
            className="border-2 border-green-500 font-bold"
            type="button"
            onClick={copyHandlerCode}
          >
            Copy Access Code
          </Button>

          <Button
            className="bg-green-500 text-white rounded-sm font-bold mt-3"
            type="button"
            onClick={handleReset}
          >
            Generate a code for a new patient
          </Button>
        </section>
      )}
    </main>
  );
};
