// components/FormikRadioGroup.tsx
import { useField, useFormikContext } from "formik";
import XRadioGroup, { XRadioGroupOption } from "./XRadioGroup";
type FormikRadioGroupProps<TValue> = {
  name: string;
  options: XRadioGroupOption<TValue>[];
  label: string;
};
const FormikRadioGroup = <TValue,>(props: FormikRadioGroupProps<TValue>) => {
  const [field] = useField<TValue>(props.name);
  const { setFieldValue } = useFormikContext();
  return (
    <XRadioGroup
      options={props.options}
      // use field.value for value
      value={field.value}
      label={props.label}
      onChange={(val: any) => {
        // use setFieldValue to modify the formikContext
        setFieldValue(props.name, val);
      }}
    />
  );
};
export default FormikRadioGroup;
