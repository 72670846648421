import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Home } from "./routes/Home";
import { AnxietyTest } from "./routes/AnxietyTest";
import { StaffAuth } from "./routes/StaffAuth";
import { PatientAuth } from "./routes/PatientAuth";
import { Admin } from "./routes/Admin";
import { BaiTest } from "./routes/BaiTest";
import { BdiTest } from "./routes/BdiTest";
import { P3Test } from "./routes/P3Test";
import { MMPITest } from "./routes/MMPITest";
import { Examination } from "./routes/Examination";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import { AuthProvider } from "./store/AuthProvider";
import { EndScreen } from "./routes/EndScreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "staff",
        element: <StaffAuth />,
      },
      {
        path: "patient",
        element: <PatientAuth />,
      },
      {
        path: "patient/:id",
        element: <Examination />,
        loader: async ({ request, params }) => {
          const id: string = params.id!;
          const docSnap = await getDoc(doc(db, "patients", id));
          if (!docSnap.exists()) throw new Error("Patient could not be found");

          const patient = docSnap.data();
          return { ...patient, accessCodeHash: "", id };
        },
      },
      {
        path: "admin",
        element: (
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        ),
      },
      {
        path: "asq",
        element: (
          <ProtectedRoute>
            <AnxietyTest />
          </ProtectedRoute>
        ),
      },
      {
        path: "bai",
        element: (
          <ProtectedRoute>
            <BaiTest />
          </ProtectedRoute>
        ),
      },
      {
        path: "bdi",
        element: (
          <ProtectedRoute>
            <BdiTest />
          </ProtectedRoute>
        ),
      },
      {
        path: "p3",
        element: (
          <ProtectedRoute>
            <P3Test />
          </ProtectedRoute>
        ),
      },
      {
        path: "mmpi",
        element: (
          <ProtectedRoute>
            <MMPITest />
          </ProtectedRoute>
        ),
      },
      {
        path: "end",
        element: <EndScreen />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
