import { FormEvent, useContext, useRef } from "react";
import Input from "../componets/UI/Input";
import Button from "../componets/UI/Button";
import AuthContext from "../store/auth-context";
import { useNavigate } from "react-router-dom";

export const StaffAuth = () => {
  const { onLogin } = useContext(AuthContext);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const handleLogin = (e: FormEvent) => {
    e.preventDefault();

    if (!emailRef.current || !passwordRef.current) return;

    onLogin(
      emailRef.current.value.trim(),
      passwordRef.current.value.trim()
    ).then(() => navigate("/admin"));
  };

  return (
    <main className="flex flex-col items-center w-full">
      <h1 className="text-center text-green-500 text-3xl font-bold my-5">
        Login
      </h1>
      <form
        className="flex flex-col justify-center items-center w-full md:w-1/2 m-3 p-2 bg-gray-100 text-green-500 rounded-md shadow-xl"
        onSubmit={handleLogin}
      >
        <Input id="email" label="Email" type="text" ref={emailRef} />
        <Input
          id="password"
          label="Password"
          type="password"
          ref={passwordRef}
        />

        <Button
          type="submit"
          className="font-bold border-green-500 border-2 rounded w-1/2 md:w-1/3"
        >
          Login
        </Button>
      </form>
    </main>
  );
};
