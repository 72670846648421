import { Menu, Transition } from "@headlessui/react";
import ArrowLeft from "../Icons/ArrowLeft";
import { Fragment, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../store/auth-context";

export default function DropMenu() {
  const { onLogout } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="outline-none inline-flex w-full justify-center gap-x-1.5 rounded-md bg-darkAccent dark:bg-primary px-3 py-2 text-sm font-bold text-primary dark:text-darkAccent shadow-sm">
          <span className="text-sm">NAV</span>
          <ArrowLeft />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-gray-200 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/admin"
                    className={
                      active
                        ? "text-blue-500 block px-4 py-2 text-sm font-bold"
                        : "text-green-500 block px-4 py-2 text-sm font-bold"
                    }
                  >
                    Auth Code Generator
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/asq"
                    className={
                      active
                        ? "text-blue-500 block px-4 py-2 text-sm"
                        : "text-green-500 block px-4 py-2 text-sm"
                    }
                  >
                    Anxiety Symptom Questionnaire
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/bai"
                    className={
                      active
                        ? "text-blue-500 block px-4 py-2 text-sm"
                        : "text-green-500 block px-4 py-2 text-sm"
                    }
                  >
                    Beck Anxiety Inventory
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/bdi"
                    className={
                      active
                        ? "text-blue-500 block px-4 py-2 text-sm"
                        : "text-green-500 block px-4 py-2 text-sm"
                    }
                  >
                    Beck Depression Inventory
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/p3"
                    className={
                      active
                        ? "text-blue-500 block px-4 py-2 text-sm"
                        : "text-green-500 block px-4 py-2 text-sm"
                    }
                  >
                    P3
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/mmpi"
                    className={
                      active
                        ? "text-blue-500 block px-4 py-2 text-sm"
                        : "text-green-500 block px-4 py-2 text-sm"
                    }
                  >
                    MMPI-2
                  </Link>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => {
                      onLogout();
                      navigate("/");
                    }}
                    className="block text-red-600 w-full px-4 py-2 text-left text-sm font-semibold"
                  >
                    Logout
                  </button>
                )}
              </Menu.Item>
            </>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
