import { PropsWithChildren, useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../store/auth-context";

export const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const { token } = useContext(AuthContext);

  if (!token) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};
