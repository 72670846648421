import { createContext } from "react";

export interface Ctx {
  token: string | null;
  onLogin: Function;
  onLogout: Function;
}

const AuthContext = createContext<Ctx>({
  token: null,
  onLogin: async (email: string, password: string) => {},
  onLogout: () => {},
});

export default AuthContext;
