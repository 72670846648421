import { useCallback, useEffect, useMemo, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { AnxietyTest } from "./AnxietyTest";
import { BaiTest } from "./BaiTest";
import { BdiTest } from "./BdiTest";
import { P3Test } from "./P3Test";
import { MMPITest } from "./MMPITest";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";

interface AssignedTests {
  patientName: string;
  asq: boolean;
  bai: boolean;
  bdi: boolean;
  p3: boolean;
  mmpi: boolean;
  id: string;
}

export const Examination = () => {
  const data = useLoaderData() as AssignedTests;

  const [tests, setTests] = useState<JSX.Element[]>([]);
  const navigate = useNavigate();

  const handleNextTest = useCallback(() => {
    setTests((state) => {
      state.shift();
      if (state.length === 0) {
        deleteDoc(doc(db, "patients", data.id));
        navigate("/end", { replace: true });
      }
      return [...state];
    });
  }, [navigate, data.id]);

  const testKeys = useMemo(
    () => [data.asq, data.bai, data.bdi, data.p3, data.mmpi],
    [data.asq, data.bai, data.bdi, data.p3, data.mmpi]
  );

  const routes: JSX.Element[] = useMemo(
    () => [
      <AnxietyTest _patient={data.patientName} _cb={handleNextTest} />,
      <BaiTest _patient={data.patientName} _cb={handleNextTest} />,
      <BdiTest _patient={data.patientName} _cb={handleNextTest} />,
      <P3Test _patient={data.patientName} _cb={handleNextTest} />,
      <MMPITest _patient={data.patientName} _cb={handleNextTest} />,
    ],
    [data.patientName, handleNextTest]
  );

  useEffect(() => {
    setTests(routes.filter((_, index) => testKeys[index]));
  }, [testKeys, routes]);

  return <>{tests && tests.length > 0 && tests[0]}</>;
};
